<template>
  <div>
    <v-row>
      <v-col cols="12" md="7" class="m-0 p-0 d-none d-sm-flex">
        <div class="sign-three relative">
          <div class="sign-three-overlay"></div>
          <div class="sign-three-content z-10">
            <div class="section-left-content" v-if="login">
              <h1 class="text-4xl font-bold text-white">
                Don't have an account?
              </h1>
              <p class="font-semibold text-sm">Register Now. It's free!</p>
              <v-btn large color="blue text-white" @click="signupBtn"
                >Register Now</v-btn
              >
            </div>
            <div class="section-left-content" v-if="signup">
              <h1 class="text-4xl font-bold text-white">
                Already have an account?
              </h1>
              <v-btn large color="blue text-white" @click="loginBtn">
                Login
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="5" class="m-0 p-0 align-center_sign-in">
        <div class="full-width m-0 p-0">
          <div
            class="flex justify-center align-center text-center md:h-screen h-full"
          >
            <div class="w-4/5 text-center">
              <div class="full-width align-center_sign-in">
                <v-img
                  style="margin-bottom: 50px; margin-top: 50px"
                  src="@/assets/logo.png"
                  max-width="300px"
                />
              </div>

              <v-form v-if="login" ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Email"
                  class="mb-4"
                  v-model="email"
                  :rules="emailRules"
                  required
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-account-lock"
                  label="Password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  name="input-10-2"
                  value=""
                  v-model="password"
                  :rules="passwordRules"
                  @click:append="show = !show"
                  required
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-domain"
                  label="Domain"
                  class="mb-4"
                  v-model="domain"
                  :rules="emptyRules"
                  required
                ></v-text-field>
                <div class="text-center">
                  <div
                    class="flex justify-center flex-wrap"
                    style="margin-left: 50px; margin-right: 50px"
                  >
                    <v-btn
                      class="mb-4"
                      @click="formSubmit"
                      block
                      color="primary"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Sign In</v-btn
                    >
                    <div class="d-flex d-sm-none">
                      <span
                        >Don't have account yet?
                        <a @click="signupBtn">Register</a></span
                      >
                    </div>
                  </div>
                </div>
              </v-form>
              <v-form v-if="signup" ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Company Name"
                  class="mb-3"
                  v-model="name"
                  :rules="emptyRules"
                  required
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Admin Email"
                  class="mb-3"
                  v-model="adminEmail"
                  :rules="emailRules"
                  required
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Admin Name"
                  class="mb-3"
                  v-model="adminName"
                  :rules="emptyRules"
                  required
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-account-lock"
                  label="Password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  name="input-10-2"
                  value=""
                  v-model="password"
                  :rules="passwordRules"
                  @click:append="show = !show"
                  required
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-domain"
                  label="Company Domain"
                  class="mb-3"
                  v-model="domain"
                  :rules="emptyRules"
                  required
                ></v-text-field>
                <v-textarea
                  name="input-10-2"
                  prepend-icon="mdi-home"
                  rows="2"
                  v-model="address"
                  label="Address"
                  value=""
                  hint="Address"
                  :rules="emptyRules"
                  required
                ></v-textarea>
                <div class="text-center">
                  <div
                    class="flex justify-center flex-wrap"
                    style="margin-left: 50px; margin-right: 50px"
                  >
                    <v-btn
                      class="mb-4"
                      @click="registerSubmit"
                      block
                      color="primary"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Register Now</v-btn
                    >
                    <div class="d-flex d-sm-none">
                      <span
                        >Already have an account?
                        <a @click="loginBtn">Login</a></span
                      >
                    </div>
                  </div>
                </div>
              </v-form>
              <br />
              <br />
              <br />
              <div class="my-4">
                <v-divider></v-divider>
                <span>Copyright First Pavilion Technologies 2021</span>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  LOGIN_QUERY,
  REGISTER_QUERY,
  GET_COMPANY_SETTINGS,
} from "../../../constants/graphql";
import { handleError } from "../../../constants/error";
import Swal from "sweetalert2";
import router from "../../../router";
import store from "../../../store";

export default {
  name: "SignIn",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Login",
  },
  data() {
    return {
      signup: false,
      login: true,
      loading: false,
      valid: true,
      show: false,
      password: null,
      email: null,
      name: null,
      address: "",
      adminName: null,
      adminEmail: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      emptyRules: [(v) => !!v || "This field is required"],
      domain: this.$store.state.authData.domain || null,
    };
  },
  methods: {
    signupBtn() {
      this.login = false;
      this.signup = true;
    },
    loginBtn() {
      this.login = true;
      this.signup = false;
    },
    formSubmit() {
      let shouldContinue = this.$refs.form.validate();
      if (shouldContinue === true) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: LOGIN_QUERY,
            variables: {
              email: this.email,
              password: this.password,
              domain: this.domain,
            },
          })
          .then((result) => {
            this.$store.dispatch("login", result.data.userLogin);
            this.loading = false;
            this.$apollo
              .query({
                query: GET_COMPANY_SETTINGS,
              })
              .then((res) => {
                let response = res.data.getCompanySettingsList;
                let currency = response.filter(
                  (elem) => elem.code == "currency_symbol"
                );
                currency.forEach(function (element, index) {
                  let data = element.value;
                  store.dispatch("setCurrency", data);
                });
                router.replace("/");
              });
            //
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    registerSubmit() {
      let shouldContinue = this.$refs.form.validate();
      if (shouldContinue === true) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: REGISTER_QUERY,
            variables: {
              name: this.name,
              adminEmail: this.adminEmail,
              adminName: this.adminName,
              password: this.password,
              domain: this.domain,
              address: this.address,
            },
          })
          .then((result) => {
            this.login = true;
            this.signup = false;
            Swal.fire({
              html: `<div>Registration successful</div>`,
              icon: "success",
              customClass: {
                popup: "format-pre",
              },
            });
          })
          .catch((error) => {
            handleError(error);
          });
        this.loading = false;
      }
    },
  },
  mounted() {
    let reg = this.$route.query.register ? true : false;
    if (reg) {
      this.signupBtn();
    }
  },
};
</script>
<style lang="scss" scoped>
* {
  overflow-x: hidden;
}

.sign-three {
  color: #fff;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 2rem;
  background: url("../../../assets/images/signin-left.jpg");
  .sign-three-overlay {
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.65);
    z-index: 1;
  }
}

.align-center_sign-in {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-width {
  width: 100%;
}
</style>
